import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import UUID from "vue-uuid";
import Axios from 'axios'
import Moment from 'vue-moment'
import VueNativeNotification from 'vue-native-notification'
import App from './App.vue'

Vue.prototype.$http = Axios

import router from './router'
import store from './store'

store.commit('initialiseStore');

require('@/scss/app.scss')

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(UUID)
Vue.use(Moment)
Vue.use(VueNativeNotification)

Vue.config.productionTip = false

/* */

Axios.interceptors.request.use(function (config) {

    let token = store.getters.token;

    if (token && config.url.includes(store.getters.api))
        config.headers.common['Authorization'] = `Bearer ${token}`;

    return config;

}, function (error) {
    return Promise.reject(error);
});

Axios.interceptors.response.use((response) => {
    
    if(response?.config.url.includes(store.getters.api)) {

        if(response.data && response.data.success === false) {

            var msg = 'Falha ao carregar os dados';

            if(response.data.messages && response.data.messages.length) {

                msg += ':\n';

                msg += response.data.messages.reduce((previous, current) => {

                    return previous + '\n   > ' + current;

                }, '');

            }

            alert(msg);

        }

    }

    return response;

}, (error) => {

    let response = error.response;
    
    if(!response?.config.url.includes(store.getters.api))
        return Promise.reject(error);

    switch(response?.status) {
        
        case 401:
            
            alert('Acesso não autorizado!');
            
            store.commit('logout');
            
            if(router.app.$route.name != 'Login')
            router.push({ name: 'Login' });
            
            break;
            
        case 403:
        
                alert('Acesso não permitido!');
        
            break;

        default:
            
            return Promise.reject(error);

    }

});

/* */

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')