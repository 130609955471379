import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/* */

let api = process.env.VUE_APP_ROOT_API;

/* */

export default new Vuex.Store({
    strict: true,

    state: {

        api: api,
        token: null,
        user: null,
        loading: false

    },

    getters: {

        api: ({ api }) => api,
        token: ({ token }) => token,
        user: ({ user }) => user,
        loading: ({ loading }) => loading,

    },

    mutations: {

        initialiseStore(state) {

            try {

                state.token = localStorage.getItem('token');
                state.user = JSON.parse(localStorage.getItem('user'));

            } catch(e) {

                console.log(e);

            }

        },
        
        login(state, data) {

            state.token = data.token;
            state.user = data.user;

            localStorage.setItem('token', data.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            
        },

        logout(state) {

            state.token = null;
            state.user = null;

            localStorage.removeItem('token');
            localStorage.removeItem('user');
            
        },

        appLoadingOn(state) {

            state.loading = true;

        },

        appLoadingOff(state) {

            state.loading = false;

        }

    }

});