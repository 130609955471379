<template>
    <div>
        
        <div :class="$style.routerView">
            <router-view />
        </div>

        <div v-if="$store.getters.loading" :class="$style.loader">
            <Loader />
        </div>

    </div>
</template>

<script>

import Loader from '@/components/Loader';

export default {

    name: 'App',

    components: {
        Loader
    }
    
}

</script>

<style type="text/scss" lang="scss">

@import 'bootstrap';
@import 'bootstrap-vue';

</style>

<style type="text/scss" lang="scss" module>

.routerView {

    position: relative;
    z-index: 1;

}

.loader {

    align-items: center;
    background: rgba(255, 255, 255, .7);
    cursor: wait;
    display: flex;
    justify-content: center;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2;

}

</style>