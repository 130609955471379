<template>
    <div :class="[$style.container, $style[color], $style[size]]">
        <span :class="$style.spinner"></span>
        <span v-if="text" :class="$style.text">{{ text }}</span>
    </div>
</template>

<script>

export default {

    name: 'Loader',

    props: {

        text: {
            default: 'Carregando...'
        },

        color: {
            default: null
        },

        size: {
            default: 'medium'
        }

    }

}

</script>

<style lang="scss" module>

$spinner-size: 30px;

.container {

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

@keyframes spin {

    from {

        transform: rotate(0deg);

    }

    to {

        transform: rotate(360deg);

    }

}

.spinner {

    animation: spin infinite .3s linear;
    display: block;
    height: $spinner-size;
    position: relative;
    width: $spinner-size;

    &:before {

        border: 4px solid transparent;
        border-bottom-color: var(--theme-color-1);
        border-radius: $spinner-size / 2;
        content: '';
        display: block;
        height: $spinner-size;
        left: 0;
        position: absolute;
        top: 0;
        width: $spinner-size;
        z-index: 2;

    }

    &:after {

        border: 4px solid rgba(0, 0, 0, .1);
        border-radius: $spinner-size / 2;
        content: '';
        display: block;
        height: $spinner-size;
        left: 0;
        position: absolute;
        top: 0;
        width: $spinner-size;
        z-index: 1;

    }

}

.text {
    
    font-size: .8rem;
    margin-top: 5px;
    text-transform: uppercase;

}

.white {

    .spinner:before {

        border-bottom-color: #fff;

    }

}

.small {

    .spinner {

        height: $spinner-size * 0.7;
        width: $spinner-size * 0.7;

        &:before {

            height: $spinner-size * 0.7;
            width: $spinner-size * 0.7;

        }

        &:after {

            height: $spinner-size * 0.7;
            width: $spinner-size * 0.7;

        }

    }

}

.smallest {

    .spinner {

        height: $spinner-size * 0.5;
        width: $spinner-size * 0.5;

        &:before {

            height: $spinner-size * 0.5;
            width: $spinner-size * 0.5;

        }

        &:after {

            height: $spinner-size * 0.5;
            width: $spinner-size * 0.5;

        }

    }

}

</style>