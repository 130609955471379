import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('@/components/Template/MainTemplate'),
        children: [
            {
                path: '',
                meta: { title: 'Bem Vindo!' },
                component: () => import('@/views/Home/Home')
            },
            {
                path: '/diagnosis',
                meta: { title: 'Diagnóstico' },
                component: () => import('@/views/Diagnosis/DiagnosisHome')
            },
            {
                path: '/database',
                meta: { title: 'Gerenciamento de Bancos de Dados' },
                component: () => import('@/views/Database/DatabaseHome')
            },
            {
                path: '/about',
                meta: { title: 'Sobre' },
                component: () => import('@/views/About/AboutHome')
            },
            {
                path: '/user',
                meta: {
                    title: 'Usuários',
                    administratorsOnly: true
                },
                component: () => import('@/views/User/UserHome')
            },
            {
                path: '/user/new',
                meta: {
                    title: 'Novo Usuário',
                    administratorsOnly: true
                },
                component: () => import('@/views/User/UserEditor')
            },
            {
                path: '/user/profile',
                meta: {
                    title: 'Painel do Usuário',
                    administratorsOnly: true
                },
                component: () => import('@/views/User/UserProfile')
            },
            {
                path: '/user/:userId',
                meta: {
                    title: 'Carregando Usuário...',
                    administratorsOnly: true
                },
                component: () => import('@/views/User/UserEditor')
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: 'Login',
            anonymous: true
        },
        component: () => import('@/views/Login/LoginHome')
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter: (to, from, next) => {

            store.commit('logout');
            
            next({ name: 'Login' });

        }
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {

    if(!to.meta.anonymous && !store.getters.token)
        next({ name: 'Login' });
    else if(to.meta.administratorsOnly && store.getters.user && store.getters.user.profileId != 1)
        next('/');
    else
        next();
    
});

const DEFAULT_TITLE = 'Talkture Admin';

router.afterEach((to) => {
    
    Vue.nextTick(() => {
        document.title = to.meta.title ? to.meta.title + ' | ' + DEFAULT_TITLE : DEFAULT_TITLE;
    });

});

export default router